import BaseIcon from "../../components/UI/BaseIcon/index";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseIcon,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.loginCharacterLimits")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.loginCharacterLimits")));
      } else {
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.conflictLoginPss")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.conflictLoginPss")));
      } else if (value !== this.formValidate4.newPw) {
        callback(new Error(this.$t("profile.conflictLoginPss")));
      } else {
        callback();
      }
    };
    const validateMPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else {
        callback();
      }
    };
    const validateMPassCheck = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("profile.enterTransferRepeat")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else if (value !== this.formValidate5.newMPw) {
        callback(new Error(this.$t("profile.conflictTransferPss")));
      } else {
        callback();
      }
    };
    const validatepw7 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else {
        callback();
      }
    };
    const validatepw7check = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.conflictTransferPss")));
      } else if (value !== this.formValidate7.pw7) {
        callback(new Error(this.$t("profile.conflictTransferPss")));
      } else {
        callback();
      }
    };
    const validateMPass8 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.transferCharacterLimits")));
      } else {
        callback();
      }
    };
    const validateMPassCheck8 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("profile.conflictTransferPss")));
      } else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
        callback(new Error(this.$t("profile.conflictTransferPss")));
      } else if (value !== this.formValidate8.newMPw8) {
        callback(new Error(this.$t("profile.conflictTransferPss")));
      } else {
        callback();
      }
    };
    return {
      baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
      fGetBackFundpwd: false,
      interval: function () {},
      imgPreview: "",
      imgNext: "",
      imgLast: "",
      loginmsg: this.$t("common.loginTip"),
      sendcodeValue: this.$t("profile.getCode"),
      uploadHeaders: { "x-auth-token": localStorage.getItem("TOKEN") },
      uploadUrl: this.host + "/uc/upload/oss/image",
      usernameS: "",
      userData: {},
      choseItem: 0,
      accountValue: "1",
      isPhoneModalOpen: false,
      formValidate2: {
        mail: "",
        vailCode1: "",
        password: "",
      },
      formValidate3: {
        mobile: "",
        vailCode2: "",
        password: "",
      },
      formValidate4: {
        oldPw: "",
        newPw: "",
        newPwConfirm: "",
        vailCode3: "",
      },
      formValidate5: {
        oldPw: "",
        newMPw: "",
        newMPwConfirm: "",
        // vailCode5: '',
      },
      formValidate6: {
        realName: "",
        idCard: "",
      },
      formValidate7: {
        pw7: "",
        pw7Confirm: "",
      },
      formValidate8: {
        newMPw8: "",
        newMPwConfirm8: "",
        vailCode5: "",
      },
      ruleValidate: {
        mail: [
          {
            required: true,
            type: "email",
            message: this.$t("profile.enterEmail"),
            trigger: "blur",
          },
        ],
        vailCode1: [
          {
            required: true,
            message: this.$t("profile.enterCode"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("profile.enterPhoneNumber"),
            trigger: "blur",
          },
        ],
        vailCode2: [
          {
            required: true,
            message: this.$t("profile.enterCode"),
            trigger: "blur",
          },
        ],
        vailCode3: [
          {
            required: true,
            message: this.$t("profile.enterCode"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.transferCharacterLimits"),
            trigger: "blur",
          },
        ],
        oldPw: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.enterTransferPss"),
            trigger: "blur",
          },
        ],
        newPw: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.loginCharacterLimits"),
            trigger: "blur",
          },
          { validator: validatePass, trigger: "change" },
        ],
        newPwConfirm: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.conflictLoginPss"),
            trigger: "blur",
          },
          { validator: validatePassCheck, trigger: "change" },
        ],
        newMPw: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.transferCharacterLimits"),
            trigger: "blur",
          },
          { validator: validateMPass, trigger: "change" },
        ],
        newMPwConfirm: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.enterTransferRepeat"),
            trigger: "blur",
          },
          { validator: validateMPassCheck, trigger: "change" },
        ],
        pw7: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.transferCharacterLimits"),
            trigger: "blur",
          },
          { validator: validatepw7, trigger: "change" },
        ],
        pw7Confirm: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.conflictTransferPss"),
            trigger: "blur",
          },
          { validator: validatepw7check, trigger: "change" },
        ],
        vailCode5: [
          {
            required: true,
            message: this.$t("profile.enterCode"),
            trigger: "blur",
          },
        ],
        realName: [
          {
            required: true,
            message: this.$t("profile.enterRealName"),
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            message: this.$t("profile.enterId"),
            trigger: "blur",
          },
        ],
        newMPw8: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.transferCharacterLimits"),
            trigger: "blur",
          },
          { validator: validateMPass8, trigger: "change" },
        ],
        newMPwConfirm8: [
          {
            required: true,
            type: "string",
            min: 6,
            message: this.$t("profile.enterTransferRepeat"),
            trigger: "blur",
          },
          { validator: validateMPassCheck8, trigger: "change" },
        ],
      },
      time1: 120, // send verification code countdown
      time2: 120, // send verification code countdown
      time3: 120, // send verification code countdown
      time5: 120, // send verification code countdown
      sendMsgDisabled1: false,
      sendMsgDisabled2: false,
      sendMsgDisabled3: false,
      sendMsgDisabled5: false,
    };
  },
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      changeFundsPassword: "userCenter/CHANGE_FUNDS_PASSWORD_SERVICE",
      setFundsPassword: "userCenter/SET_FUNDS_PASSWORD_SERVICE",
      recoverFundsPassword: "userCenter/RECOVER_FUNDS_PASSWORD_SERVICE",
      getFundsPasswordVerificationCode:
        "userCenter/GET_FUNDS_PASSWORD_VERIFICATION_CODE_SERVICE",
      getMemberData: "userCenter/GET_MEMBER_DATA_SERVICE",
    }),
    beforeUpload(data) {
      if (data && data.size >= 1024000 * 2) {
        this.$toast.error("upload image size cannot exceed 2 m");
        return false;
      }
    },
    noPhone() {
      this.$toast.warning(this.$t("profile.bindPhoneTip"));
      this.showItem(3);
    },
    showItemFundpwd() {
      this.fGetBackFundpwd = false;
      this.handleReset("formValidate5");
      this.showItem(5);
    },
    renderPw() {
      this.$Modal.confirm({
        title: this.$t("profile.resetTransferPss"),
        onOk: () => {
          this.$toast.warning("Clicked ok");
        },
        render: (h) => {
          return h("Input", {
            props: {
              value: this.value,
              autofocus: true,
            },
            on: {
              input: (val) => {
                this.value = val;
              },
            },
          });
        },
      });
    },
    submit(name) {
      //change fund password
      if (name === "formValidate5") {
        let payload = {
          oldPassword: this.formValidate5.oldPw,
          newPassword: this.formValidate5.newMPw,
        };
        this.changeFundsPassword(payload)
          .then((response) => {
            if (response.data.code === 0) {
              this.$toast.success(this.$t("profile.successSave"));
              this.handleReset("formValidate5");
              this.getUserData();
              this.choseItem = 0;
            }
          })
          .catch((err) => {
            this.$toast.error(err.message);
          });
      }
      //set fund password
      if (name === "formValidate7") {
        let payload = {
          jyPassword: this.formValidate7.pw7,
        };
        this.setFundsPassword(payload)
          .then((response) => {
            if (response.data.code === 0) {
              this.$toast.success(this.$t("profile.successSave"));
              this.getUserData();
              this.choseItem = 0;
            }
          })
          .catch((err) => {
            this.$toast.error(err.message);
          });
      }
      //recover funds password
      if (name === "formValidate8") {
        let payload = {
          newPassword: this.formValidate8.newMPw8,
          code: this.formValidate8.vailCode5,
        };
        this.recoverFundsPassword(payload)
          .then((response) => {
            if (response.data.code === 0) {
              this.$toast.success(this.$t("profile.successSave"));
              this.fGetBackFundpwd = false;
              this.handleReset("formValidate5");
              this.getUserData();
              this.choseItem = 0;
            }
          })
          .catch((err) => {
            this.$toast.error(err.message);
          });
      }
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.submit(name);
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    showItem(index) {
      this.choseItem = index;
    },
    send() {
      this.sendMsgDisabled5 = true;
      // if (this.userData.mobilePhone) {
        this.getFundsPasswordVerificationCode()
          .then((response) => {
            if (response.data.code === 0) {
              this.$toast.success(response.data.message);
              this.setOtpInterval();
            } else if (response.data.code === 302) {
              this.sendMsgDisabled5 = false;
              this.isPhoneModalOpen = true;
            }
          })
          .catch((err) => {
            this.$toast.error(err.message);
            this.sendMsgDisabled5 = false;
          });
      // } else {
      //   this.$toast.success(this.$t("profile.enterPhoneNumber"));
      // }
    },
    confirmUserHasNoMobile() {
      window.location.replace(this.baseUrl + "/panel/account/kycresult");
    },
    setOtpInterval() {
      // this.sendMsgDisabled5 = false;
      this.interval = setInterval(() => {
        this.sendcodeValue = this.time5;
        if (this.time5-- <= 0) {
          this.time5 = 120;
          window.clearInterval(this.interval);
          this.sendcodeValue = this.$t("profile.getCode");
          this.sendMsgDisabled5 = false;
        }
      }, 1000);
    },
    getUserData() {
      //obtain personal safety information
      this.getMemberData().then((response) => {
        if (response.data.code === 0) {
          this.userData = response.data.data;
          this.usernameS = this.userData.username.slice(0, 1);
        }
        // else {
        //   this.$Message.error(this.loginmsg);
        // }
      });
    },
  },
  computed: {
    ...mapGetters({
      getMember: "global/MEMBER",
      getLang: "global/GET_LANG",
    }),
    member() {
      return this.getMember;
    },
    lang() {
      return this.getLang;
    },
  },
  created() {
    this.setNavigation("nav-profile");
    this.getUserData();
    // let level = this.$store.getters.member.memberRate;
    // level == 0 && (this.memberlevel = "ordinaryMember");
    // level == 1 && (this.memberlevel = "superGroupOwner");
    // level == 2 && (this.memberlevel = "superPartner");.
  },
  mounted() {},
};
